import { useState } from 'react';
import unitsStyle from '../../style/units.module.css';
import { AgentCustom } from '../Agent/AgentCreate';

export const TopAccodian = (props) => {
    const {title, badgeText, ContentComp, subText, compProps} = props;
    const arrowRightUrl = "/img/units/accodian_arrow_right.png";
    const arrowDownUrl = "/img/units/accodian_arrow_down.png";
    const isContentOpen = compProps.isContentOpen;
    const setIsContentOpen = compProps.setIsContentOpen;

    const openContents = () => {
        setIsContentOpen(res => {
            if (ContentComp === AgentCustom) {
                return [!res[0], res[1]];
            } else {
                return [res[0], !res[1]];
            }
        })
    }
    
    return (
        <div 
            className={unitsStyle.Accodian__Container}
        >
            <div
                className={unitsStyle.Accodian__TopWrapper}
            >
                <div
                    className={unitsStyle.Accodian__TopLeft}
                >
                    <div className={unitsStyle.Accodian__BadgeWrapper}>
                        <div
                            className={unitsStyle.Accodian__TopBadge}
                        >
                            <p className="h8 fc-prim-800">{badgeText}</p>
                        </div>
                        <div className="spacing-12" />
                        <p className="h4 fc-gray-700">{title}</p>
                    </div>
                    {
                        subText &&
                        <div>
                            <div className="spacing-12" />
                            <p className='h7 fc-gray-600'>{subText}</p>
                        </div>
                    }
                </div>
                <img 
                    onClick={() => {openContents()}}
                    src={isContentOpen ? arrowDownUrl : arrowRightUrl} width={24} height={24} 
                    className='pointer'
                />
            </div>
            {
                isContentOpen &&
                <div className={unitsStyle.Accodian__BottomWrapper}>
                    <ContentComp compProps={compProps} />
                </div>
            }
        </div>
    )
}