import { useEffect, useState } from "react"
import widgetStyle from '../../style/units.module.css';
import agentStyle from '../../style/agent.module.css';
import { InfoMessage } from "../Units/Alerts";
import { updateChatbotInfo } from "../../apis/services/create/api";
export const WidgetSet = (props) => {
    const {compProps} = props;
    const [clickedButton, setClickedButton] = useState([false, false]);

    const handleButtonClick = (e) => {
        const buttonId = e.target.dataset.buttonid;
        let clickedButtonCopy = [...clickedButton];
        clickedButtonCopy[buttonId] = !clickedButtonCopy[buttonId];
        setClickedButton(clickedButtonCopy);
    }

    return (
        <div>
            <div
                className={widgetStyle.WidgetSet__Container}
            >
                <button
                    data-buttonid={0}
                    className={clickedButton[0] ? widgetStyle.WidgetSet__Button__active : widgetStyle.WidgetSet__Button}
                    onClick={(e) => {handleButtonClick(e)}}
                >
                    <p className={`h6 ${clickedButton[0] ? 'fc-prim-800': 'fc-gray-700'}`}>플로팅 버튼</p>
                </button>
                <div className="spacing-8" />
                <button
                    className={widgetStyle.WidgetSet__Button__disabled}
                    disabled={true}
                >
                    <p className="h6 fc-gray-400">추천 섹션(준비 중인 기능이에요)</p>
                </button>
            </div>
            {
                clickedButton[0] &&
                <>
                    <div style={{borderBottom: '2px dashed #e1e1e1'}}>
                        <div className="spacing-32" />
                    </div>
                    <div>
                        <div className="spacing-32" />
                        <PosCustom compProps={compProps} />
                    </div>
                </>
            }
        </div>
    )
}

export const PosCustom = (props) => {
    const {compProps} = props;
    const setIsToastVisible = compProps.setIsToastVisible;
    const initSettings = compProps.initSettings;
    const updateSetting = compProps.updateSetting;
    const setIsModalVisible = compProps.setIsModalVisible;
    const partnerId = sessionStorage.getItem('ptid');
    const partnerType = sessionStorage.getItem('ptt');
    const chatbotId = sessionStorage.getItem('cbid');
    const userAccessLevel = sessionStorage.getItem('l');

    const [fbPos, setFbPos] = useState({
        top: null,
        bottom: 66,
        left: null,
        right: 0,
    });

    useEffect(() => {
        updateSetting('position', fbPos);
    }, [fbPos])
    

    return (
        <div>
            <div className={widgetStyle.Accodian__TopWrapper} style={{paddingLeft: 0, paddingRight: 0}}>
                <div className={widgetStyle.Accodian__BadgeWrapper}>
                    <div
                        className={widgetStyle.Accodian__TopBadge}
                    >
                        <p className="h8 fc-prim-800">위젯설정</p>
                    </div>
                    <div className="spacing-12" />
                    <p className="h4 fc-gray-700">플로팅 버튼</p>
                </div>
                <InfoMessage text={'하단의 배포 버튼을 누르면 쇼핑몰에 게시됩니다'} />
            </div>
            <div style={{width: '100%', height: 'fit-content', display: 'flex'}}>
                <div style={{width: '640px', height: '360px', background: '#efefef', borderRadius: '20px', position: 'relative'}}>
                    <img src="/img/units/designmode-fb.png" width={105} height={32} style={{position: 'absolute', bottom: fbPos.bottom/2.5, right: fbPos.right/2.5}}/>
                </div>
                <div className="spacing-32" />
                <div style={{flex: 1, height: '360px', display: 'flex'}}>
                    <p className="h6 fc-gray-600" style={{minWidth: '70px'}}>위치 조정</p>
                    <div className="spacing-24" />
                    <div style={{width: '100%'}}>
                        <div style={{width: '100%', display: 'flex'}}>
                            <label htmlFor="posx" className="h6 fc-gray-600" style={{minWidth: '26px'}}>가로</label>
                            <div className="spacing-16" />
                            <input 
                                type="range" 
                                name="posx"
                                style={{width: '100%'}} 
                                min={0}
                                max={1495}
                                value={fbPos.right}
                                onChange={(e) => {
                                    setFbPos(res => 
                                        ({
                                            ...res,
                                            right: Number(e.target.value),
                                        }))
                                }} 
                                disabled={true}
                            />
                        </div>
                        <div className="spacing-32" />
                        <div style={{width: '100%', display: 'flex'}}>
                            <label htmlFor="posy" className="h6 fc-gray-600" style={{minWidth: '26px'}}>세로</label>
                            <div className="spacing-16" />
                            <input 
                                type="range" 
                                name="posy"
                                style={{width: '100%'}} 
                                min={0}
                                max={200}
                                onChange={(e) => {
                                    setFbPos(res => 
                                        ({
                                            ...res,
                                            bottom: Number(e.target.value),
                                        }))
                                }} 
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={agentStyle.AgentCustom__ButtonWrap__col}>
                {
                    Number(userAccessLevel) === 0 &&
                    <button style={{width: '75px', height: '33px', background: '#444', borderRadius: '4px', cursor: 'default'}}>
                        <p className="h8 fc-white">유료 플랜</p>
                    </button>
                }
                <div className="spacing-8" />
                <button 
                    className={(Number(userAccessLevel) === 0 || partnerType === 'dlst') ? agentStyle.AgentCustom__Button__Inactive : agentStyle.AgentCustom__Button} 
                    onClick={() => setIsModalVisible(true)}
                    disabled={Number(userAccessLevel) === 0 || partnerType === 'dlst'}  
                >
                    <p className='h4 fc-white'>배포</p>
                </button>
            </div>
        </div>
    )
}