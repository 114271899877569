
export const ConfirmModal = (props) => {
    const {title, subText, submitFunc, setIsModalVisible} = props;

    return (
        <div style={{width: '450px', height: '184px', background: '#fff', borderRadius: '20px', border: '1px solid #e1e1e1', boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.25)', padding: '24px'}}>
            <p className="h4 fc-gray-700">{title}</p>
            <div className="spacing-12" />
            <p className="h7 fc-gray-700">{subText}</p>
            <div className="spacing-32" />
            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                <button 
                    style={{width: '84px', height: '47px', background: '#fff', border: '1px solid #e1e1e1', borderRadius: '10px'}}
                    onClick={() => {
                        setIsModalVisible(false)
                    }}
                >
                    <p className="h6 fc-gray-600">취소하기</p>
                </button>
                <div className="spacing-8" />
                <button 
                    style={{width: '84px', height: '47px', background: '#154cca', border: '1px solid #154cca', borderRadius: '10px'}}
                    onClick={() => {
                        submitFunc()
                        setIsModalVisible(false)
                    }}
                >
                    <p className="h6 fc-white">적용하기</p>
                </button>
            </div>
        </div>
    )
}