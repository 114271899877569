import axios from "axios";

export const signup = async (inputList, mallId = '', shopUserId = '') => {
    let payload = {};
    for (const [key, val] of Object.entries(inputList)) {
        payload[val.nameInput] = val.value;
    }

    try {
        const response = await axios.post(
            process.env.REACT_APP_SIGNUP_URL,
            {
                email: payload.email,
                password: payload.password,
                partnerCompanyName: payload.partnerCompanyName,
                mallUrl: payload.mallUrl,
                manager: payload.manager,
                cafe24MallId: mallId,
                cafe24UserId: shopUserId,
            },{
                headers: {}
            }
        )
        return response.data;
    } catch (error) {
        console.error(`Error while calling signup api: ${error}`)
    }
}

export const login = async (email, password) => {
    // temp code before GENT-968
    if (email === 'selentest@email.com') {
        return {
            randomId: 'selentest',
            clientId: 'test',
            accessLevel: 4,
            chatbotList: [
                {
                    chatbotName: 'test',
                    chatbotId: 1,
                }
            ]
        }
    } else {
        // og code part for login
        try {
            const response = await axios.post(
                process.env.REACT_APP_AUTH_URL,
                {
                    email: email,
                    password: password
                },{
                    headers: {}
                }
            )
            return response.data
            // const accessLevel = convertAccessLevel('Pro');
            // return {
            //     randomId: response.data.randomId,
            //     clientId: 'dlst',
            //     accessLevel: accessLevel,
            //     chatbotList: [
            //         {
            //             chatbotName: 'test',
            //             chatbotId: 1,
            //         }
            //     ]
            // }
        } catch (error) {
            console.error(`Error while calling login api: ${error}`)
        }
    }
}

export const getAccountInfo = async (partnerId) => {
    try {
        const response = await axios.get(
            process.env.REACT_APP_ACCOUNTINFO_URL + `/${partnerId}`,
            {
                params: {},
                headers: {}
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error while calling getAccountInfo api: ${error}`);
    }
}

export const postScript = async (partnerId) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_SCRIPTTAG_URL + `/${partnerId}`,
            {
                shopNo: 1,
                src: "https://d32xcphivq9687.cloudfront.net/sdk-cafe24.js",
                displayLocation: [
                  "MAIN",
                  "PRODUCT_LIST",
                  "PRODUCT_DETAIL",
                ],
                excludePath: [],
            },{
                headers: {}
            }
        )

        console.log('postScriptTest res', response);
    } catch (error) {
        console.error(`Error while calling postScriptTest api: ${error}`);
    }
}

export const getScript = async (partnerId) => {
    try {
        const response = await axios.get(
            process.env.REACT_APP_SCRIPTTAG_URL + `/${partnerId}/list`,
            {
                params: {
                    partnerId: partnerId,
                },
                headers: {}
            }
            
        )

        console.log('getScriptTest res', response);
    } catch (error) {
        console.error(`Error while calling postScriptTest api: ${error}`);
    }
}

export const postScriptTest = async (partnerId) => {
    try {
        const response = await axios.post(
            'https://slch7uufzk.execute-api.ap-northeast-2.amazonaws.com/api/cafe24/script-tag/67286d4e9cd363ad4ded2195',
            {
                shopNo: 1,
                src: "https://d32xcphivq9687.cloudfront.net/sdk-cafe24.js",
                displayLocation: [
                  "all"
                ],
                excludePath: [],
                skinNo: [
                  2
                ],
            },{
                headers: {}
            }
        )

        console.log('postScriptTest res', response);
    } catch (error) {
        console.error(`Error while calling postScriptTest api: ${error}`);
    }
}

export const getScriptTest = async (partnerId) => {
    try {
        const response = await axios.get(
            'https://slch7uufzk.execute-api.ap-northeast-2.amazonaws.com/api/cafe24/script-tag/67286d4e9cd363ad4ded2195/list',
            {
                params: {
                    partnerId: '67286d4e9cd363ad4ded2195',
                },
                headers: {}
            }
            
        )

        console.log('getScriptTest res', response);
    } catch (error) {
        console.error(`Error while calling postScriptTest api: ${error}`);
    }
}
